import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import headerStyles from "./Header.module.css"

const Navigation = (props) => {
  const {originalPath} = useI18next();
  const {t} = useTranslation();

  return (
    <nav className={`${headerStyles.header} ${props.className}`}>
      <ul>
        { !props.hideHomeLink &&
          <li style={{margin: 0}}>
            <Link
              to="/">
              {t('homepageTitle')}
            </Link>
          </li>
        }
        <li>
          <Link to={originalPath} language={'ru'}>
            Russian
          </Link>
        </li>
        <li>
          <Link to={originalPath} language={'en'}>
            English
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
